import React, { Component } from "react";
import { rulesTabs as tabs } from "../../common/constants";
import Absence from "./Absence";
import Admissions from "./Admissions";
import ParentsDosAndDonts from "./ParentsDosAndDonts";
import StudentsDosAndDonts from "./StudentsDosAndDonts";
import Timings from "./Timings";
import Uniform from "./Uniform";

class Rules extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: tabs[0],
    };
  }

  render() {
    const activeTab = this.state.activeTab;
    return (
      <div className="academics-content">
        <div className="academics-heading">
          <h1>Rules & Regulations</h1>
        </div>
        <div className="tabs wow fadeInUp" data-wow-delay=".1s">
          <ul>
            {tabs.map((tabName, index) => {
              return (
                <li
                  className={activeTab === tabName ? " active-tab " : ""}
                  onClick={() => {
                    this.setState({ activeTab: tabName });
                  }}
                  key={"tab-" + index}
                >
                  {tabName} <span></span>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="tab-content">
          {activeTab === "School Timings" ? <Timings /> : null}
          {activeTab === "School Uniform" ? <Uniform /> : null}
          {activeTab === "Students Do and Don'ts" ? (
            <StudentsDosAndDonts />
          ) : null}
          {activeTab === "Parents Do and Don'ts" ? (
            <ParentsDosAndDonts />
          ) : null}
          {activeTab === "Admissions | Withdrawals" ? <Admissions /> : null}
          {activeTab === "Leave of Absence" ? <Absence /> : null}
        </div>
      </div>
    );
  }
}

export default Rules;
