import "../../sass/pages/gallery.scss";
import ChildLabourImg from "../../assets/images/Posters/ChildLabour.png";
import DoctorsDayImg from "../../assets/images/Posters/DoctorsDay.png";
import FathersDayImg from "../../assets/images/Posters/FathersDay.png";
import FriendshipDayImg from "../../assets/images/Posters/FriendshipDay.png";
import InternationalJusticeDayImg from "../../assets/images/Posters/InternationalJusticeDay.png";
import LeftHandersDayImg from "../../assets/images/Posters/LeftHandersDay.png";
import NelsonMadelaDayImg from "../../assets/images/Posters/NelsonMadelaDay.png";
import OnamImg from "../../assets/images/Posters/Onam.png";
import PhotoContestImg from "../../assets/images/Posters/PhotoContest.png";
import TeachersDayImg from "../../assets/images/Posters/TeachersDay.jpeg";
import WomenEqualityImg from "../../assets/images/Posters/WomenEquality.png";
import WorldPopulationDayImg from "../../assets/images/Posters/WorldPopulationDay.png";
import YouthDayImg from "../../assets/images/Posters/YouthDay.jpeg";

import photo_1 from "../../assets/images/photo_1.jpg";
import photo_2 from "../../assets/images/photo_2.jpg";
import photo_3 from "../../assets/images/photo_3.jpg";
import photo_4 from "../../assets/images/photo_4.jpg";
import photo_5 from "../../assets/images/photo_5.jpg";
import photo_6 from "../../assets/images/photo_6.jpg";
import photo_7 from "../../assets/images/photo_7.jpg";
import photo_8 from "../../assets/images/photo_8.jpg";
import photo_9 from "../../assets/images/photo_9.jpg";

function Gallery() {
  return (
    <div className="gallery-page">
      {/* <h3>Posters</h3> */}
      <div className="posters">
        {/* <img src={ChildLabourImg} alt="" />
        <img src={DoctorsDayImg} alt="" />
        <img src={FathersDayImg} alt="" />
        <img src={FriendshipDayImg} alt="" />
        <img src={InternationalJusticeDayImg} alt="" />
        <img src={LeftHandersDayImg} alt="" />
        <img src={NelsonMadelaDayImg} alt="" />
        <img src={OnamImg} alt="" /> */}
        <img src={photo_1} alt="" />
        <img src={photo_2} alt="" />
        <img src={photo_3} alt="" />
        <img src={photo_4} alt="" />
        <img src={photo_5} alt="" />
        <img src={photo_6} alt="" />
        <img src={photo_7} alt="" />
        <img src={photo_8} alt="" />
        <img src={photo_9} alt="" />
        {/* <img src={PhotoContestImg} alt="" />
        <img src={TeachersDayImg} alt="" />
        <img src={WomenEqualityImg} alt="" />
        <img src={WorldPopulationDayImg} alt="" />
        <img src={YouthDayImg} alt="" /> */}
      </div>
    </div>
  );
}

export default Gallery;
