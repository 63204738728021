import ManagementImg from "assets/images/rules.jpg";
import SkillsImg from "assets/images/SkillsVenn.png";

function Management() {
  return (
    <div className="management-container">
      <div className="head-image"></div>

      <div className="management-details">
        <h1>Management</h1>
        <div className="focus-container">
          <ul>
            <li>
              Innovations in teaching-learning methodologies by devising
              students friendly and students centred paradigms
            </li>
            <li>Reforms in examinations and evaluation practices</li>
            <li>Skill learning by adding job-oriented and job-linked inputs</li>
            <li>
              Regularly updating the pedagogical skills of the teachers and
              administrators by conducting in service training programmes,
              workshops etc.
            </li>
          </ul>
        </div>

        <div className="skills-container" id="focus">
          <h1>Our Focus</h1>
          <div className="skills-detail-row">
            <p>
              There is an increased awareness among the educators of the need to
              integrate what are called as 21st Century skills in educational
              systems. There are three key 21stcentury skills
            </p>
            <img src={SkillsImg} width="450px" height="400px" />
            <div>
              <div className="skill-card">
                <div>
                  <h2> Learning Skills </h2>
                  <span>Critical Thinking</span>
                  <span>Creativity</span>
                  <span>Communication</span>
                  <span>Collaboration</span>
                </div>
              </div>

              <div className="skill-card">
                <div>
                  <h2> Literacy Skills </h2>
                  <span>Information literacy</span>
                  <span>Media literacy </span>
                  <span>Technology literacy</span>
                </div>
              </div>

              <div className="skill-card">
                <div>
                  <h2>
                    Life <br /> Skills{" "}
                  </h2>
                  <span>Flexibility</span>
                  <span>Leadership</span>
                  <span>Initiative</span>
                  <span>Productivity</span>
                  <span>Self-awareness</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Management;
