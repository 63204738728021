import React from 'react';
import { Route } from "react-router";

import "../../sass/pages/academics.scss";
import Rules from "components/rules"
// import Achievements from 'components/achievements';
import Faculty from 'components/faculty';
import Department from 'components/departments';
import StudentCouncil from 'components/studentCouncil';

function Academics(props) {
  return (
    <div className="academics-container">
      <Route path="/academics/rules" component={Rules} />
      <Route exact path="/academics/departments" component={Department} />
      <Route exact path="/academics/faculty" component={Faculty} />
      <Route exact path="/academics/student" component={StudentCouncil} />
      {/* <Route exact path="/academics/achievements" component={Achievements} /> */}
    </div>
  );
}

export default Academics;