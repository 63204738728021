// import { motion } from "framer-motion";
// import Classroom from "assets/images/classroom.png";
// import Teacher from "assets/images/teacher.png";
// import Note from "assets/images/note.png";
import Navbar from "components/navbar";
import IntroSlider from "components/introSlider";
import Vision from "components/vision";
import Principal from "components/principal";
import Objectives from "components/objectives";
import Management from "components/management";
import { Link, useHistory } from "react-router-dom";
import UpcomingEvents from "components/upcomingEvents";
import Feedbacks from "components/Feedbacks";

function Home() {
  const history = useHistory();
  return (
    <div className="home-page">
      <IntroSlider />
      <Vision />
      {/* <Principal /> */}
      <Objectives />
      {/* <div className="move-about-us" onClick={() => history.push("/about")}>
        <div>Abouts Us</div>
      </div> */}
      <UpcomingEvents />
      <Feedbacks />
    </div>
  );
}

export default Home;

{
  /* <IntroSlider /> */
}

{
  /* <div className="landing-cards">
<div className="card">
  <span>
    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id quae
    illo optio, doloribus voluptatum rerum? Quam iste ducimus iure ab
    adipisci quis commodi quas dolor in eveniet, alias reprehenderit
    eaque?
  </span>
  <img src={Teacher} />
</div>
<div className="card">
  <img src={Note} />
  <span>
    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Soluta
    accusantium ea consectetur excepturi! Distinctio blanditiis rem
    repudiandae eum, earum voluptates exercitationem repellendus ipsum,
    ipsa mollitia perferendis esse ullam soluta eveniet?
  </span>
</div>
</div> */
}

{
  /* <div className="info-cards-conatiner">
        <motion.div
          className="info-card"
          whileHover={{
            scale: 1.1,
            transition: { duration: 0.5 },
          }}
        >
          <img src={Classroom} />
        </motion.div>
        <motion.div
          className="info-card"
          whileHover={{
            scale: 1.1,
            transition: { duration: 0.5 },
          }}
        >
          <img src={Classroom} />
        </motion.div>
        <motion.div
          className="info-card"
          whileHover={{
            scale: 1.1,
            transition: { duration: 0.5 },
          }}
        >
          <img src={Classroom} />
        </motion.div>
      </div> */
}
