import Logo from "assets/images/Logo.png";
import { navBarOptions, navOptions } from "common/constants";
import { RouteHelperContext } from "common/customRouteNavigator";
import { motion } from "framer-motion";
import { useContext } from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useCallback } from "react";
import EnquiryForm from "../../assets/NAM_VM_Enquiry_Form.pdf";
import AdmissionForm from "../../assets/NAM_Admission_Form.pdf";
import MenuIcon from "assets/images/menu.png";

function Navbar() {
  const history = useHistory();
  const location = useLocation();
  const customRouterContext = useContext(RouteHelperContext);
  const [hoverOption, setHoverOption] = useState("");

  const [showNavOptions, setShowNavOptions] = useState(false);

  const isSmallScreen = useMediaQuery({
    query: "(max-device-width: 900px)",
  });

  const isLargeScreen = useMediaQuery({
    query: "(min-device-width: 900px)",
  });

  const subMenuAnimate = {
    enter: {
      opacity: 1,
      rotateX: 0,
      transition: {
        duration: 0.5,
      },
      display: "block",
    },
    exit: {
      opacity: 0,
      rotateX: -15,
      transition: {
        duration: 0.5,
        delay: 0,
      },
      transitionEnd: {
        display: "none",
      },
    },
  };

  const NavOptions = () => {
    return (
      <div className="options-container">
        <div className="nav-options">
          {Object.keys(navBarOptions).map((option) => (
            <motion.div
              className="menu-item"
              onHoverStart={() => setHoverOption(option)}
              onHoverEnd={() => setHoverOption("")}
              // onClick={() => toggleHoverMenu(option)}
            >
              <span
                onClick={() => {
                  if (isSmallScreen) {
                    if (navBarOptions[option].path == "/") {
                      history.push("/");
                      setShowNavOptions(false);
                    } else {
                      setHoverOption(option);
                    }
                  } else {
                    navBarOptions[option].path != "-" &&
                      history.push(navBarOptions[option].path);
                  }
                }}
              >
                {option.toUpperCase()}
              </span>

              <motion.div
                className="sub-menu"
                initial="exit"
                animate={hoverOption == option ? "enter" : "exit"}
                variants={subMenuAnimate}
              >
                {navBarOptions[option].subRoutes.map((menu) => {
                  return (
                    <div
                      className="sub-menu-item"
                      onClick={() => {
                        setShowNavOptions(false);
                        customRouterContext &&
                          customRouterContext.scrollIntoView(menu.path);
                      }}
                    >
                      {menu.name}
                    </div>
                  );
                })}
              </motion.div>
            </motion.div>
          ))}
        </div>

        <div
          className="admission-btn"
          onClick={() => window.open(AdmissionForm, "_blank", "fullscreen=yes")}
        >
          Admissions
        </div>
      </div>
    );
  };

  return (
    <div className="navbar">
      <div className="logo-container">
        <img src={Logo} id="logo" />
        <div id="logo-name">Nam Vidhya Mandir</div>
      </div>

      {isSmallScreen && (
        <div onClick={() => setShowNavOptions(true)}>
          <img src={MenuIcon} width={30} height={30} />
        </div>
      )}
      {isSmallScreen && showNavOptions && <NavOptions />}

      {isLargeScreen && <NavOptions />}
    </div>
  );
}

export default Navbar;
