import React, { useContext, useEffect } from "react";
import { Fragment } from "react";
import { useLocation } from "react-router";
import { RouteHelperContext } from "./customRouteNavigator";

export default function WithHashScroll({ children }) {
  const location = useLocation();
  const customRouterContext = useContext(RouteHelperContext);

  useEffect(() => {
    customRouterContext && customRouterContext.pageInItScroll();
  }, [location.pathname]);

  return <Fragment>{children}</Fragment>;
}
