import ClassImg from "assets/images/classroom.jpg";
import PeerImg from "assets/images/peerLearning.jpg";

function SchoolEnv() {
  return (
    <div className="school-env-container">
      <div className="head-image"></div>
      <div className="school-env-details">
        <h1>School and Class Environment </h1>
        <div>
          <div className="classroom-image"> </div>
          {/* <img src={ClassImg} width={600} height={400} /> */}
          Nam Vidhya Mandir environment is conducive for holistic development of
          the students. Nam Vidhya Mandir focuses on health and hygiene by
          adopting inclusive practices. As part of the policy our school adopts
          practices which promotes mental health. In this direction, our school
          follows the guidelines issued by the Board on making the school a
          No-Anger Zone or Anger Free Zone.
          <br />
          <br />
          The time table in our school takes care of proper rest and intake of
          healthy foods and the children learn subjects with relaxation
          <br />
          <br />
          Our teachers make efforts to draw examples and group activities from
          daily life observations within the classroom/within the school and
          surroundings, and encourage presentations and reflection by our
          students once the activity is completed, to develop the skills of
          critical thinking and communication.
        </div>

        <div>
          {/* <img src={PeerImg} width={600} height={600} /> */}
          <div className="teacher-image"> </div>
          Our teachers make efforts to draw examples and group activities from
          daily life observations within the classroom/within the school and
          surroundings, and encourage presentations and reflection by our
          students once the activity is completed, to develop the skills of
          critical thinking and communication
          <br />
          <br />
          Children learn a lot through peer learning. To promote peer learning,
          flexible seating arrangements will be made available during the
          classroom transactions. Learning focuses on individual differences and
          promotes collaborative learning. The classroom activities will be
          connected to the immediate environment of children
          <br />
          <br />
          To address the Health aspect of HPE, qualified doctors will examine
          children once in the academic year along with a follow-up session
          during the year. School will also bring any noticeable disability in a
          student to the notice of the school counsellor and parents. Cases of
          special needs of students with medical history will be carefully noted
          and handled accordingly.
        </div>
      </div>
    </div>
  );
}

export default SchoolEnv;
