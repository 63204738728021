import { upcomingEventsData } from "common/constants";
import { format, parseISO } from "date-fns";
import { API_END_POINT }from "../../common/constants"
import React from "react"
import API from "../../common/axios-utils"
import { useState } from "react";

function UpcomingEvents() {
  const [data , setData] = useState([])
  React.useEffect(()=>{
    API("GET", "/events", {}, {}).then((response)=>{setData(response.data)}).catch((err)=>console.log(err))
  } , [])
  return (
    <div className="upcoming-events-container">
      <h2 className="headere">Upcoming Events</h2>
      <div className="event-box-container">
        {data
          .filter((event) => {
            return  event.is_enabled;
          })
          .map((event) => {
            const day = format(new Date(event.date), "dd");
            const month = format(new Date(event.date), "LLLL");
            return (
              <div className="event-box">
                <p className="date">{day}</p>
                <p className="month">{month}</p>
                <p className="data">{event.name}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default UpcomingEvents;
