import React, { createContext, useState } from "react";
import { useHistory, useLocation } from "react-router";

export const RouteHelperContext = createContext(null);

export default function RouteHelperProvider({ children }) {
  const history = useHistory();
  const location = useLocation();

  const scrollIntoView = (path) => {
    if (path.length == 0) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }

    if (location.pathname != path && path.length > 1) {
      history.push(path);
    }

    if (path.includes("#")) {
      const id = path.split("#")[1];
      const element = document.querySelector("#" + id);
      // console.log(id, element);
      element && element.scrollIntoView();
    }
  };

  const pageInItScroll = () => {
    scrollIntoView(location.hash);
  };

  return (
    <RouteHelperContext.Provider
      value={{
        scrollIntoView: scrollIntoView,
        pageInItScroll: pageInItScroll,
      }}
    >
      {children}
    </RouteHelperContext.Provider>
  );
}
