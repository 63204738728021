import "../../sass/animate.css";

function Amenity(props) {
  const amenity = props.amenity;
  return (
    <section id={amenity.id} className="amenity-section pt-75">
      <div className="container">
        <div className={props.number % 2 === 0 ? `row reverse` : `row`}>
          <div className="col-lg-6">
            <div className="amenity-img wow fadeInUp" data-wow-delay=".5s">
              <img
                src={process.env.PUBLIC_URL + amenity.imgPath}
                alt={amenity.heading}
              />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="amenity-content">
              <div className="section-title">
                <span className="wow fadeInUp" data-wow-delay=".2s">
                  {amenity.heading}
                </span>
                <h1 className="wow fadeInUp" data-wow-delay=".4s">
                  {amenity.subHeading}
                </h1>
                <p className="wow fadeInUp" data-wow-delay=".6s">
                  {amenity.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Amenity;
