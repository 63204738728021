import PTAQualificationImage from "assets/images/studentQualification.png";
import ManagementImg from "assets/images/management.jpg";
import MonitorResponsibilities from "assets/images/studentMonitor.png";
import { Fragment } from "react";

function StudentCommittee() {
  return (
    <div className="student-committee-container">
      <div className="head-image"></div>

      <div className="student-committee-details">
        <Fragment>
          <h1>Student Governing Council</h1>
          <p>
            Student Governing Council members are primarily the role models of
            the school. They are usually responsible for representing the school
            at events, and therefore must be able to make public speeches. They
            also serve as a good role model for students, and share pupils'
            ideas with the school's leadership. They also lead the class
            monitors in their duties
          </p>

          <img src={MonitorResponsibilities} className="student-qual-image" />
        </Fragment>

        <Fragment>
          <h1>Parent Teacher Committee</h1>
          <p>
            Education is a three-pronged activity, one is the school, second the
            student and, third the parents community.
          </p>
          <div>
            <img src={PTAQualificationImage} className="student-qual-image" />
          </div>
        </Fragment>
      </div>
    </div>
  );
}

export default StudentCommittee;
