import axios from "axios";
import { API_END_POINT } from "./constants";

const APIConfig = axios.create({
  baseURL: API_END_POINT,
  timeout: 20000,
});

const API = (method, url, data, headers) => {
  return APIConfig({
    method,
    url,
    data,
    headers,
  });
};

export default API;
