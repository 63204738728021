import PImage from "assets/images/principal.jpg";

function Principal() {
  return (
    <div className="principal-container">
      <img src={PImage} />
      <div>
        <h2>Janagalakshmi</h2>
        <span>Principal</span>
        <p>
          With a dream to contribute to the sector of education and to make
          education accessible to all, especially in rural areas, she has
          extensively contributed to the educational institute setup. Her rich
          experience has helped in the successful execution of the school
          project.
        </p>
      </div>
    </div>
  );
}

export default Principal;
