import { Fragment } from "react";

function Vision() {
  return (
    <Fragment>
      <div className="vision-container">
        <h2 className="welcome">Welcome To</h2>
        <h2 className="school-name">Nam Vidhya Mandir</h2>
        <div className="bar"></div>
        <p>
          Our vision is to empower students to acquire, demonstrate, articulate
          and value knowledge and skills that will support them, as life-long
          learners, to participate in and contribute to the global world and
          practise the core values of the school: respect, tolerance &
          inclusion, and excellence.
        </p>
      </div>
      {/* <div className="vision-container">
        <h3>Our Motto</h3>
        <p>Learning by doing</p>
      </div> */}
    </Fragment>
  );
}

export default Vision;
