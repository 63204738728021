import SchoolEnv from "components/schoolEnvironment";
import StudentCommittee from "components/studentsCommittee";
import { Route, Switch, useRouteMatch } from "react-router";
const { default: Management } = require("components/management");
const { default: Principal } = require("components/principal");
const { Fragment, useEffect } = require("react");

const AboutUs = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="about-us-container">
      <Route path="/about/management" component={Management} />
      <Route exact path="/about/environment" component={SchoolEnv} />
      <Route exact path="/about/student" component={StudentCommittee} />
    </div>
  );
};

export default AboutUs;
