import { feedbackData } from "common/constants";
import Marquee from "react-fast-marquee";
import Anitha from "../../assets/images/Feedbacks/Anitha.jpg";
import Sathya from "../../assets/images/Feedbacks/Sathya.jpg";
import Yamini from "../../assets/images/Feedbacks/Yamini.jpg";
import Santhiya from "../../assets/images/Feedbacks/Santhiya.jpg";
import Deepika from "../../assets/images/Feedbacks/Deepika.jpg";
import Krishnakanth from "../../assets/images/Feedbacks/Krishnakanth.jpg";
import Revathi from "../../assets/images/Feedbacks/Revathi.jpg";
import Unknown from "../../assets/images/Feedbacks/Unknown.jpg";
import Thilaga from "../../assets/images/Feedbacks/Thilaga.jpg";
import Bhuvaneshwari from "../../assets/images/Feedbacks/Bhuvaneshwari.jpg";
import Nivithitha from "../../assets/images/Feedbacks/Nivithitha.jpg";
import SelvaMeena from "../../assets/images/Feedbacks/SelvaMeena.jpg";
import MahaLakshmi from "../../assets/images/Feedbacks/MahaLakshimi.jpg";
import Logo from "assets/images/Logo.png";


import "./index.css";

function Feedbacks() {
  const getImage = (image) => {
    if(image == "Anitha"){
      return Anitha
    }else if(image == "Sathya"){
      return Sathya
    }else if(image == "Yamini"){
      return Yamini
    }else if(image == "Santhiya"){
      return Santhiya
    }else if(image == "Deepika"){
      return Deepika
    }else if(image == "Krishnakanth"){
      return Krishnakanth
    }else if(image == "Revathi"){
      return Revathi
    }else if(image == "Thilaga"){
      return Thilaga
    }else if(image == "Bhuvaneshwari"){
      return Bhuvaneshwari
    }else if(image == "Nivithitha"){
      return Nivithitha
    }else if(image == "SelvaMeena"){
      return SelvaMeena
    }else if(image == "MahaLakshmi"){
      return MahaLakshmi
    }else if(image == "Unknown"){
      return Unknown;
    }
    return Logo
  }
  return (
    <div className="upcoming-events-container">
      <h2 className="headere">Feedbacks</h2>
      <div className="event-box-container">
        <Marquee speed={150} pauseOnHover={true} delay={3}>
          {feedbackData.map((feedback) => {
            return (
              <div className="event-box" style={{ minHeight: "32rem" }}>
                <div className="img-container">
                  <img src={getImage(feedback.image)} className="feedback-img" />
                  <p className="date">{feedback.name}</p>
                </div>
                <p className="data">{feedback.feedback}</p>
              </div>
            );
          })}
        </Marquee>
      </div>
    </div>
  );
}

export default Feedbacks;
