import { amenitiesDetails } from "../../common/constants";
import "../../sass/pages/amenities.scss";
const { default: AmenitiesList } = require("components/amenitiesList");
const { default: Amenity } = require("components/amenity");

function Amenities() {
  return (
    <div className="amenities-page">
      <AmenitiesList />
      {amenitiesDetails.map((amenity, index) => (
        <Amenity amenity={amenity} number={index} />
      ))}
    </div>
  );
}

export default Amenities;
