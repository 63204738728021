import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import SchoolImage from "assets/images/school.jpg";
import SchoolFront from "assets/images/schoolFront.jpg";
import Lab from "assets/images/lab.jpg";
import Principal from "components/principal";

function IntroSlider() {
  // 0 - left
  // 1 - right
  const [[pageIndex, direction], setPage] = useState([0, 1]);
  const [time, setTime] = useState(0);

  const introSliderCards = [
    <div className="slider-card card-2"></div>,
    <div className="slider-card card-1"></div>,
    <Principal />,
  ];

  const sliderVariants = {
    enter: (direction) => {
      return {
        zIndex: 0,
        x: direction > 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: -1000,
        opacity: 0,
      };
    },
  };

  const paginate = (newDirection) => {
    const newPageIndex =
      (newDirection == 1 ? pageIndex + 1 : pageIndex - 1) %
      introSliderCards.length;
    setPage([newPageIndex, newDirection]);
  };

  // Infinite Timer to run Slider
  useEffect(() => {
    const timer = setTimeout(() => {
      setTime(time + 1);
      // paginate(1);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [time]);

  return (
    <div className="slider-container">
      <AnimatePresence>
        <motion.div
          key={pageIndex}
          custom={direction}
          variants={sliderVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.1 },
          }}
        >
          {introSliderCards[pageIndex]}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export default IntroSlider;
