import "./sass/styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import { Route, Switch, BrowserRouter } from "react-router-dom";
import ContactUs from "components/contactUs";
import OverlayPoster from "components/poster";
import Home from "pages/home";
import AboutUs from "pages/aboutUs";
import Academics from "pages/academics"
import Navbar from "components/navbar";
import Amenities from "pages/amenities";
import RouteHelperProvider from "common/customRouteNavigator";
import WithHashScroll from "common/withHashScroll";
import Gallery from "pages/gallery";

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <RouteHelperProvider>
          <WithHashScroll>
            <OverlayPoster />
            <Navbar />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/about/" component={AboutUs} />
              <Route path="/academics/" component={Academics} />
              <Route exact path="/amenities" component={Amenities} />
              <Route exact path="/gallery" component={Gallery} />
            </Switch>
            <ContactUs />
          </WithHashScroll>
        </RouteHelperProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
