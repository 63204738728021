import { Fragment, useState } from "react";
import PosterImg from "assets/images/Poster.jpg";

const OverlayPoster = () => {
  const [showPoster, setShowPoster] = useState(false);

  return (
    <Fragment>
      {showPoster && (
        <Fragment>
          <div className="modal-bg"></div>
          <div className="poster-container">
            <img src={PosterImg} />
            <span onClick={() => setShowPoster(false)}>close</span>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default OverlayPoster;
