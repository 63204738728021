import { studentsDosAndDonts } from "../../common/constants";

function StudentsDosAndDonts(props) {
  return (
    <div className="dos-and-donts">
      <h3 className="wow fadeInUp" data-wow-delay=".2s">
        Students Do And Don'ts
      </h3>
      <ul className="row">
        {studentsDosAndDonts.map((content, index) => {
          return (
            <li className="col-lg-6 col-md-5 wow fadeInUp" data-wow-delay=".4s">
              <span>{index + 1}</span>
              <p>{content}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default StudentsDosAndDonts;
