export const navOptions = ["About Us", "Amenities", "School Information"];

export const API_END_POINT = "https://namvidhyamandir.herokuapp.com"

export const navBarOptions = {
  Home: {
    path: "/",
    subRoutes: [],
  },
  "About Us": {
    path: "-",
    subRoutes: [
      { name: "Management", path: "/about/management" },
      { name: "Student Council", path: "/about/student" },
      { name: "School Environment", path: "/about/environment" },
    ],
  },
  Academics: {
    path: "-",
    subRoutes: [
      { name: "Rules & Regulation", path: "/academics/rules" },
      // { name: "Departments", path: "/academics/departments" },
      // { name: "Faculty Action Teams", path: "/academics/faculty" },
      // { name: "Student Council", path: "/academics/student" },
      // { name: "Achievements", path: "/academics/achievements" },
    ],
  },
  Amenities: {
    path: "/amenities",
    subRoutes: [
      { name: "Transport", path: "/amenities#transport" },
      { name: "Sports & Games", path: "/amenities#sports" },
      { name: "Labs", path: "/amenities#labs" },
      { name: "Culturals", path: "/amenities#culturals" },
      { name: "Library", path: "/amenities#library" },
      { name: "Canteen", path: "/amenities#canteen" },
      { name: "Classroom", path: "/amenities#classroom" },
      { name: "Campus", path: "/amenities#campus" },
      { name: "Restroom", path: "/amenities#restroom" },
    ],
  },
  Gallery: {
    path: "/gallery",
    subRoutes: [],
  },
};

export const amenitiesOptions = ["Sports", "Lab", "Bus"];

export const ObjectivePoints = [
  "To achieve cognitive, affective and psychomotor excellence",
  "To enhance self-awareness and explore innate potential",
  "To attain mastery over laid down competencies",
  "To imbibe 21st century learning, literacy and life skills",
  "To promote goal setting, and lifelong learning",
  "To inculcate values and foster cultural learning and international understanding in an interdependent society",
  "To acquire the ability to utilize technology and information for the betterment of humankind",
  "To strengthen knowledge and attitude related to livelihood skills",
  "To develop the ability to appreciate art and show case talents",
  "To promote physical fitness, health and well-being",
  "To promote arts integrated learning",
];

export const amenitiesDetails = [
  {
    id: "transport",
    heading: "Transport",
    subHeading: "Safe and secured transportation",
    imgPath: "/amenities/transport-bus.png",
    description:
      "Facilities made in our school helps us to improve the quality of the study environment in the school, thus improving the quality of education, holistic and all-round development of our students.",
  },
  {
    id: "sports",
    heading: "Sports and Games",
    subHeading: "Healthy life",
    imgPath: "/amenities/playground.JPG",
    description:
      "Facilities made in our school helps us to improve the quality of the study environment in the school, thus improving the quality of education, holistic and all-round development of our students.",
  },
  {
    id: "labs",
    heading: "Labs",
    subHeading: "Indispensable to understand the subject",
    imgPath: "/amenities/laboratory.jfif",
    description:
      "Facilities made in our school helps us to improve the quality of the study environment in the school, thus improving the quality of education, holistic and all-round development of our students.",
  },
  {
    id: "",
    heading: "Physics Laboratory",
    subHeading: "Undertand the concepts of physics",
    imgPath: "/amenities/physics-lab.jfif",
    description:
      "Facilities made in our school helps us to improve the quality of the study environment in the school, thus improving the quality of education, holistic and all-round development of our students.",
  },
  {
    id: "",
    heading: "Chemistry Laboratory",
    subHeading: "Undertand the concepts of Chemistry",
    imgPath: "/amenities/chemistry-lab.jfif",
    description:
      "Facilities made in our school helps us to improve the quality of the study environment in the school, thus improving the quality of education, holistic and all-round development of our students.",
  },
  {
    id: "",
    heading: "Biology Laboratory",
    subHeading: "Undertand the concepts of Biology",
    imgPath: "/amenities/biology-lab.jfif",
    description:
      "Facilities made in our school helps us to improve the quality of the study environment in the school, thus improving the quality of education, holistic and all-round development of our students.",
  },
  {
    heading: "Computer Laboratory",
    subHeading: "Have a hands on practices",
    imgPath: "/amenities/computer-lab.JPG",
    description:
      "Facilities made in our school helps us to improve the quality of the study environment in the school, thus improving the quality of education, holistic and all-round development of our students.",
  },
  {
    id: "culturals",
    heading: "Culturals",
    subHeading: "An outlet for the aesthetic skills",
    imgPath: "/amenities/culturals.jfif",
    description:
      "Facilities made in our school helps us to improve the quality of the study environment in the school, thus improving the quality of education, holistic and all-round development of our students.",
  },
  {
    id: "library",
    heading: "Library",
    subHeading: "Heaven for passionate readers",
    imgPath: "/amenities/library-books.jpg",
    description:
      "Facilities made in our school helps us to improve the quality of the study environment in the school, thus improving the quality of education, holistic and all-round development of our students.",
  },
  {
    id: "canteen",
    heading: "Canteen",
    subHeading: "Enjoy the leisure time",
    imgPath: "/amenities/canteen.jfif",
    description:
      "Facilities made in our school helps us to improve the quality of the study environment in the school, thus improving the quality of education, holistic and all-round development of our students.",
  },
  {
    id: "classroom",
    heading: "Classroom",
    subHeading: "The place to learn",
    imgPath: "/amenities/classroom.JPG",
    description:
      "The classrooms in our school are designed to provide a comfortable and conducive learning environment for students.The classrooms in our school are designed to provide a comfortable and conducive learning environment for students.",   
  },
  {
    id: "Campus",
    heading: "Campus",
    subHeading: "Dynamic, engaging, comprehensive, safe",
    imgPath: "/amenities/campus.JPG",
    description:
      "Our school campus is a vibrant and dynamic environment that fosters learning, growth, and community.Whether in the classroom or out on the grounds, our campus provides a welcoming and supportive environment that inspires our students to achieve their full potential.",   
  },
  {
    id: "restroom",
    heading: "Restroom Facilities",
    subHeading: "Safe and Hygienic",
    imgPath: "/amenities/restroom.JPG",
    description:
      "Our school's restroom facilities are designed to provide a safe, hygienic, and comfortable environment for our students and staff. Located conveniently throughout the school, our restrooms are equipped with modern amenities and are regularly cleaned and maintained to ensure optimal hygiene",   
  }
];

export const rulesTabs = [
  "School Timings",
  // "School Uniform",
  "Students Do and Don'ts",
  "Parents Do and Don'ts",
  // "Admissions | Withdrawals",
  // "Leave of Absence",
];

export const timings = [
  { session: "Morning", timing: "08:30 AM to 12:30 PM" },
  { session: "Lunch", timing: "12.30 PM to 01.05 PM" },
  { session: "Evening", timing: "01.05 PM to 04.40 PM" },
];

export const parentsDosAndDonts = [
  "Parents are requested to co-operate with the school authorities by enforcing regularity and discipline, by ensuring that the children prepare their lessons and by taking an active interest in the activities of the school.",
  "Parents are informed that remarks by the teachers are made in the Pupil's diary-cum-calendar, when necessary . They are requested to check the diary-cum-calendar and sign it, indicating that they have seen the remarks.",
  "Parents are expected to sign, at least once a fortnight,the exercise books of their children,their periodic progress reports or any other similar documents, when requested to do so. Failure to do so may put their children to inconvenience.",
  "Parents/Guardians are requested not to apply for leave of absence for their children or request to see them during lessons, unless absolutely necessary .",
  "If a student is likely to be absent due to illness for a long period, the Principal must be informed in writing as soon as possible to request leave of absence.",
  "When communicating with the Principal, parents/guardians are requested to mention in the letter, besides the name of the student, his/her class and section.",
  "Parents/guardians are especially requested to notify the school of any change in their address and telephone numbers as soon as possible.",
  "Parents/guardians are requested not to send the children to the school for at least a fortnight after they have recovered from infectious or contagious diseases like chickenpox, measles, mumps, etc., and until they are medically fit.They should bring the necessary medical certificate of fitness to school and hand it over to the Class Teacher.",
  "Parents/guardians or other persons are not permitted to meet the teachers during school hours without the permission of the Principal/Headmistress. On urgent matters,the staff may be seen by prior appointment, when they are free.",
  "Improper conduct reflecting character, persistent irregularity, continual neglect of studies, disobedience, insubordination and disrespect towards any staff member, malpractice at test and examinations,thieving,immoral behaviour, smoking or chewing tobacco,use of drugs and liquor and other serious intentional offences are punishable by expulsion.",
];

export const studentsDosAndDonts = [
  "All students come under school rules from the beginning of the journey to school until the end of their journey back home.",
  "All students should, at all times, maintain a high standard of manners, in particular.",
  "All the buttons, except the top one, of the shirt will be fastened.",
  "Informal dress is permitted only on excursions, outings or expeditions or Whenever permitted by the teacher incharge of the particular class on child's birthday.",
  "Students should always be dressed in the prescribed school uniform or games dress, wherever applicable.",
  "Clothes and shoes not of the regulation pattern, or in any way, unsuitable, will not be allowed.",
  "All boys must have regulation in haircut (side burns and long hair on nape, mushroom cut or crew cut are forbidden & beard or stubble is not permitted).",
  "Children who wish to wear informal clothes on their birthday should avoid jeans, T-shirts or tight fitting clothes. (please seek class teacher's advice in this regard)",
];

export const upcomingEventsData = [
  {
    date: new Date("06/05/2021").toString(),
    data: "World Environment Day",
  },
  {
    date: new Date("06/07/2021").toString(),
    data: "World Food Safety Day",
  },
  {
    date: new Date("06/08/2021").toString(),
    data: "World Ocean Day",
  },
  {
    date: new Date("06/12/2021").toString(),
    data: "Anti-Child Labour Day",
  },
  {
    date: new Date("06/14/2021").toString(),
    data: "World Blood Donor Day",
  },
  {
    date: new Date("06/15/2021").toString(),
    data: "World Wind Day",
  },
  {
    date: new Date("06/20/2021").toString(),
    data: "World Refugee Day / Fathers Day",
  },
  {
    date: new Date("06/21/2021").toString(),
    data: "International Yoga Day",
  },
  {
    date: new Date("07/01/021").toString(),
    data: "Doctor's Day",
  },
  {
    date: new Date("07/11/2021").toString(),
    data: "World Population Day",
  },
  {
    date: new Date("08/08/2021").toString(),
    data: "International Friendship Day",
  },
  {
    date: new Date("08/29/2021").toString(),
    data: "National Sports Day",
  },
  {
    date: new Date("09/08/2021").toString(),
    data: "International Literacy Day",
  },
  {
    date: new Date("10/11/2021").toString(),
    data: "International Girl Child Day",
  },
  {
    date: new Date("10/15/2021").toString(),
    data: "World Students Day",
  },
  {
    date: new Date("11/19/2021").toString(),
    data: "Karthigai Deepam",
  },
  {
    date: new Date("12/10/2021").toString(),
    data: "Human Rights Day",
  },
  {
    date: new Date("12/22/2021").toString(),
    data: "National Mathematics Day",
  },
];

export const feedbackData = [
  {
    image : "Anitha",
    name: "Anitha",
    feedback: "It is the right place to nurture the talents and achieve the life time goals. School environment is very healthy and hygienic",
  },
  {
    image : "Sathya",
    name: "Sathya",
    feedback: "Good school with excellent motto. Hardworking and nice understanding teachers. Children not only excel in mark they are grown as good decision makers. Lively environment to study.",
  },
  {
    image : "Yamini",
    name: "Yamini",
    feedback: "It is very wonderful place to study. when we enter the school we get positive vibes. It is green and clean. Teachers are kind and best.",
  },
  {
    image : "Santhiya",
    name: "Santhiya",
    feedback: "Awesome education. I really like the way of teaching the children and the environment is very compatible and refresh for our mind and soul. It's like a heaven.",
  },
  {
    image : "Deepika",
    name: "Deepika",
    feedback: "An excellent team of staff nurturing children to a better future!",
  },
  {
    image : "Krishnakanth",
    name: "Krishana kanth",
    feedback: "Nam Vidhya Mandir is the place where passion meets profession. The staffs of Nam Vidhya Mandir are seasoned professionals who bring about a depth of experience, much needed for any educational institute . It is a wonderful environment to learn and explore for students.",
  },
  {
    image : "Revathi",
    name: "Revathi",
    feedback: "It provides learning space and learning environment under the direction of teachers. The school has a good infrastructure in terms of building and supporting facilities. It is a very good school for those who wish to achieve in life",
  },
  {
    image : "Unknown",
    feedback: "If you want to provide  your children as right  education where they are nurtured to be their best, most confident selves then I encourage you to seek out this School. Teachers are passionate about seeing their students success.",
    name: "Unknown",
  },
  {
    image : "Thilaga",
    name: "Thilaga",
    feedback: "Our correspondent cum Principal Ms.Janagalakshmi S, designs her own curriculum that imparts knowledge and instils creativity in the young minds to drive their ambition",
  },
  {
    image : "Bhuvaneshwari",
    name: "Bhuvaneshwari",
    feedback: " This school management provides a beautiful platform to learn and nurture the talents of students. It is a right place to enrich and update our knowledge towards the world",
  },
  {
    image : "Nivithitha",
    name: "Nivithitha ",
    feedback: "The school gives an independent in learning which brings out the inner talents of the each and every students. Patriotism is also present in our activities. Education has been a very positive experience for students",
  },
  {
    image : "SelvaMeena",
    name: "SelvaMeena",
    feedback: "The management gives equal importance to the curricular and co-curricular activities.Children participated with an enthusiasm and succeeded.Their assessment technique is too different from other schools and it make the students to enjoy learning with understanding not to memorize the book",
  },
  {
    image : "MahaLakshmi",
    name: "MahaLakshmi ",
    feedback: "If you want to give your children as great education while they are nurtured to be their best, most confident selves then I encourage you to seek out this School. Teachers are passionate about seeing their students succeed",
  },
];