import { navBarOptions, navOptions } from "common/constants";
import { ReactComponent as Instagram } from "assets/svg/instagram.svg";
import { ReactComponent as Facebook } from "assets/svg/facebook.svg";
import Logo from "assets/images/Logo.png";
import LocationImg from "assets/images/location.png";
import PhoneImg from "assets/images/phone.png";
import MailImg from "assets/images/mail.png";
import { Link } from "react-router-dom";

function ContactUs() {
  return (
    <div className="contact-us-container">
      <div className="site-info-container">
        <div className="contact-info">
          <div>
            <img src={Logo} width={80} height={80} />
            <h4>Nam Vidhya Mandir</h4>
          </div>

          <div>
            <div className="contact-detail">
              <img src={LocationImg} width={23} height={30} />
              <div>
                <span>Paalpannai Road</span>
                <br />
                <span>T.Vadipatti, Madurai </span>
                <br /> <span>Tamil Nadu 625218</span>
              </div>
            </div>

            <div className="contact-detail">
              <img src={PhoneImg} width={25} height={25} />
              <span>
                9094889835 <br /> 7397615166
              </span>
            </div>

            <div className="contact-detail">
              <img src={MailImg} width={25} height={25} />
              <span>namvidhyamandir@gmail.com</span>
            </div>
          </div>
        </div>

        <div className="site-map">
          <div className="col">
            <Link to="/">
              <h4>Home</h4>
            </Link>

            <h4>AboutUs</h4>

            {navBarOptions["About Us"].subRoutes.map((route) => {
              return (
                <Link to={route.path}>
                  <p> {route.name}</p>
                </Link>
              );
            })}
          </div>

          <div className="col">
            <h4>Amenities</h4>
            {navBarOptions["Amenities"].subRoutes.map((route) => {
              return (
                <Link to={route.path}>
                  <p> {route.name}</p>
                </Link>
              );
            })}
          </div>
        </div>

        <div className="social">
          <span>Follow Us</span>
          <div className="social-icons">
            <a
              href="https://instagram.com/namvidhyamandir?igshid=5ug5ftw51fvx"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram />
            </a>
            <a
              href="https://www.facebook.com/namvidhya.mandir"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook />
            </a>
          </div>
        </div>
      </div>

      {/* <div>Learning by doing</div>
      <div>
        <span>&#169;</span> 2021 Nam Vidhya Mandir
      </div> */}
    </div>
  );
}

export default ContactUs;
