import { RouteHelperContext } from "common/customRouteNavigator";
import { useContext } from "react";

function AmenitiesList() {
  const customRouterContext = useContext(RouteHelperContext);

  return (
    <section className="service-section pt-50">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8">
            <div className="section-title text-center mb-70">
              <h1 className="wow fadeInUp" data-wow-delay=".2s">
                Amenities
              </h1>
              <span className="wow fadeInUp" data-wow-delay=".4s">
                To improve the quality of the study environment
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div
              className="single-service wow fadeInUp"
              data-wow-delay=".2s"
              onClick={() => {
                customRouterContext &&
                  customRouterContext.scrollIntoView("/amenities#transport");
              }}
            >
              <div className="icon">
                <img
                  src={process.env.PUBLIC_URL + "/amenities/transport.png"}
                  alt=""
                  width="100%"
                />
              </div>
              <div className="content">
                <h3>Transport</h3>
                <p>To improve the quality of the study environment.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div
              className="single-service wow fadeInUp"
              data-wow-delay=".4s"
              onClick={() => {
                customRouterContext &&
                  customRouterContext.scrollIntoView("/amenities#sports");
              }}
            >
              <div className="icon">
                <img
                  src={process.env.PUBLIC_URL + "/amenities/sports.jpg"}
                  alt=""
                  width="100%"
                />
              </div>
              <div className="content">
                <h3>Sports & Games</h3>
                <p>To improve the quality of the study environment.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div
              className="single-service wow fadeInUp"
              data-wow-delay=".6s"
              onClick={() => {
                customRouterContext &&
                  customRouterContext.scrollIntoView("/amenities#labs");
              }}
            >
              <div className="icon">
                <img
                  src={process.env.PUBLIC_URL + "/amenities/labs.png"}
                  alt=""
                  width="100%"
                />
              </div>
              <div className="content">
                <h3>Labs</h3>
                <p>To improve the quality of the study environment.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div
              className="single-service wow fadeInUp"
              data-wow-delay=".2s"
              onClick={() => {
                customRouterContext &&
                  customRouterContext.scrollIntoView("/amenities#culturals");
              }}
            >
              <div className="icon">
                <img
                  src={process.env.PUBLIC_URL + "/amenities/cultural.png"}
                  alt=""
                  width="100%"
                />
              </div>
              <div className="content">
                <h3>Culturals</h3>
                <p>To improve the quality of the study environment.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div
              className="single-service wow fadeInUp"
              data-wow-delay=".4s"
              onClick={() => {
                customRouterContext &&
                  customRouterContext.scrollIntoView("/amenities#library");
              }}
            >
              <div className="icon">
                <img
                  src={process.env.PUBLIC_URL + "/amenities/library.png"}
                  alt=""
                  width="100%"
                />
              </div>
              <div className="content">
                <h3>Library</h3>
                <p>To improve the quality of the study environment.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div
              className="single-service wow fadeInUp"
              data-wow-delay=".6s"
              onClick={() => {
                customRouterContext &&
                  customRouterContext.scrollIntoView("/amenities#canteen");
              }}
            >
              <div className="icon">
                <img
                  src={process.env.PUBLIC_URL + "/amenities/canteen.png"}
                  alt=""
                  width="100%"
                />
              </div>
              <div className="content">
                <h3>Canteen</h3>
                <p>To improve the quality of the study environment.</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div
              className="single-service wow fadeInUp"
              data-wow-delay=".6s"
              onClick={() => {
                customRouterContext &&
                  customRouterContext.scrollIntoView("/amenities#classroom");
              }}
            >
              <div className="icon">
                <img
                  src={process.env.PUBLIC_URL + "/amenities/classroom.png"}
                  alt=""
                  width="100%"
                />
              </div>
              <div className="content">
                <h3>Classroom</h3>
                <p>The place to learn</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div
              className="single-service wow fadeInUp"
              data-wow-delay=".6s"
              onClick={() => {
                customRouterContext &&
                  customRouterContext.scrollIntoView("/amenities#campus");
              }}
            >
              <div className="icon">
                <img
                  src={process.env.PUBLIC_URL + "/amenities/campus.png"}
                  alt=""
                  width="100%"
                />
              </div>
              <div className="content">
                <h3>Campus</h3>
                <p>Dynamic and Vibrant</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div
              className="single-service wow fadeInUp"
              data-wow-delay=".6s"
              onClick={() => {
                customRouterContext &&
                  customRouterContext.scrollIntoView("/amenities#restroom");
              }}
            >
              <div className="icon">
                <img
                  src={process.env.PUBLIC_URL + "/amenities/toilet.png"}
                  alt=""
                  width="100%"
                />
              </div>
              <div className="content">
                <h3>Restroom facilities</h3>
                <p>Safe & Hygiene</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

export default AmenitiesList;
