import { ObjectivePoints } from "common/constants";
import BrainImg from "assets/images/brain.png";
import ArtImg from "assets/images/art.png";
import BallImg from "assets/images/ball.png";

function Objectives() {
  return (
    <div className="section-container">
      <h1>The Objectives of Nam Vidhya Mandir is to</h1>
      <div className="objectives-container">
        <div className="objective-container">
          <img src={BrainImg} />
          <span>Achieve cognitive, affective and psychomotor excellence</span>
          <span>To enhance self-awareness and explore innate potential</span>
          <span>
            To acquire the ability to utilize technology and information for the
            betterment of humankind
          </span>
        </div>

        <div className="objective-container">
          <img src={BallImg} />
          <span>To promote physical fitness, health and well-being</span>
          <span>
            To strengthen knowledge and attitude related to livelihood skills
          </span>
          <span>To promote goal setting, and lifelong learning</span>
        </div>

        <div className="objective-container">
          <img src={ArtImg} />
          <span>To imbibe 21st century learning, literacy and life skills</span>
          <span>
            To develop the ability to appreciate art and show case talents
          </span>
          <span>To promote arts integrated learning</span>
        </div>
      </div>
    </div>
  );
}

export default Objectives;
