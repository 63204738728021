import { timings } from "../../common/constants";
function Timings(props) {
  return (
    <div className="timings">
      <h3 className="wow fadeInUp" data-wow-delay=".2s">
        School Office Timings
      </h3>
      <ul>
        {timings.map(({ session, timing }) => {
          return (
            <li className="wow fadeInUp" data-wow-delay=".4s">
              <p>{session}</p>
              <p>{timing}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Timings;
